import React from 'react';
import { navigate } from 'gatsby';
import { container } from './sign-in.module.scss';

import pagesContext from '../../../config/pages-context';
import { useGetUserQuery } from '../../redux/api/user';

import LoginFormWithLink from '../molecules/login-form-with-link';

export interface ISignInProps {
    className?: string;
}

const SignIn: React.FC<ISignInProps> = ({ className = '' }) => {
    const { isSuccess } = useGetUserQuery();

    if (isSuccess) {
        navigate(`/${pagesContext.aes.slug}`, { replace: true });
    }

    return (
        <section className={`${className} ${container}`}>
            <LoginFormWithLink />
        </section>
    );
};

export default SignIn;
