import * as Yup from 'yup';

import { FormGeneratorInitialValues, TFormFieldsFactoryProps } from '../models/form-field.model';

export const loginForm = ({ t }: TFormFieldsFactoryProps<'LoginForm'>) =>
    [
        {
            name: 'email',
            type: 'email',
            placeholder: t.email.label,
            initialValue: '',
            schema: Yup.string().email(t.email.error.email).required(t.email.error.required),
        },
        {
            name: 'password',
            type: 'password',
            placeholder: t.password.label,
            initialValue: '',
            schema: Yup.string().required(t.password.error.required),
        },
        {
            name: 'submit',
            type: 'submit',
            label: t.submit.label,
        },
    ] as const;

export type TLoginFormInitialValues = FormGeneratorInitialValues<
    ReturnType<typeof loginForm>,
    string
>;

export const orderLoginForm = ({ t }: TFormFieldsFactoryProps<'OrderLoginForm'>) =>
    [
        {
            name: 'email',
            type: 'email',
            label: t.email.label,
            initialValue: '',
            schema: Yup.string().email(t.email.error.email).required(t.email.error.required),
        },
        {
            name: 'password',
            type: 'password',
            label: t.password.label,
            initialValue: '',
            schema: Yup.string().required(t.password.error.required),
        },
        {
            name: 'submit',
            type: 'submit',
            label: t.submit.label,
        },
    ] as const;
