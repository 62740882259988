import React, { useEffect } from 'react';
import { Link, navigate } from 'gatsby';

import {
    container,
    form,
    link,
    reminderText,
    formGrid,
    title,
    subtitle,
} from './login-form-with-link.module.scss';

import { loginForm } from '../../formik/login.form';
import useTranslations from '../../hooks/use-translations';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import { useUser } from '../../hooks/use-user';
import pagesContext from '../../../config/pages-context';

import FormGenerator from './form-generator';

export interface ILoginFormWithLinkProps {
    className?: string;
}

const LoginFormWithLink: React.FC<ILoginFormWithLinkProps> = ({ className = '' }) => {
    const t = useTranslations('LoginFormWithLink');
    const userState = useUser();
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(userState.login.errors);

    useEffect(() => {
        if (userState.login.isSuccess) {
            navigate(`/${pagesContext.aes.slug}`);
        }
    }, [userState.login]);

    return (
        <div className={[container, className].join(' ')}>
            <h1 className={title}>{t.title}</h1>
            <p className={subtitle}>{t.subtitle}</p>
            <FormGenerator
                formRef={formikRef}
                className={form}
                formClassName={formGrid}
                formikProps={{ onSubmit: (values) => userState.login.fetch(values) }}
                name="LoginForm"
                fields={loginForm}
                errorMessage={globalErrorMessage}
                submitLoading={userState.login.isLoading}
            />
            <p className={reminderText}>
                {t.question}{' '}
                <Link to={`/${pagesContext.passwordRecovery.slug}`} className={link}>
                    {t.link}
                </Link>
            </p>
        </div>
    );
};

export default LoginFormWithLink;
